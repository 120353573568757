.autoCompleteForm {
  display: flex;
  .ant-row {
    flex-direction: column;

    .ant-col {
      label {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 1rem;
        color: #989fb9;
      }
      &.ant-form-item-label {
        text-align: left;
      }
    }

    &.name {
      margin-bottom: 0;
      // margin-right: 1.8rem;
      // width: 16.6rem;
      min-width: 26.5%;
      margin-right: 3%;
    }
    &.count {
      margin-bottom: 0;
      // margin-right: 1.1rem;
      // width: 9.5rem;
      width: 14.5%;
      margin-right: 2%;
    }
    &.price {
      margin-bottom: 0;
      // margin-right: 1.4rem;
      // width: 12rem;
      width: 18.5%;
      margin-right: 2%;
      .ant-input-number {
        width: 100%;
      }
    }
    &.totalPrice {
      margin-bottom: 0;
      margin-right: 1rem;
      // width: 10rem;
      width: 16%;
      #totalPrice {
        font-weight: normal;
        font-size: 18px;
        color: #7944e3;
        margin-bottom: 0;
      }
    }
    &.saveBtn {
      margin-bottom: 0;
      // margin-left: 2rem;
      margin-left: 3%;
      label {
        &::after {
          display: none;
        }
      }
      button {
        margin: 0 auto;
        border: none;
        background: transparent;
        cursor: pointer;
        svg {
          width: 1.25rem;
          height: 1.25rem;
          opacity: 0.5;
        }
      }
    }
  }
}
.specific {
  .ant-row {
    &.name {
      min-width: 26% !important;
      margin-right: 3% !important;
    }
    &.count {
      width: 15% !important;
      margin-right: 2% !important;
    }
    &.price {
      width: 19% !important;
      margin-right: 2% !important;
    }
    &.totalPrice {
      width: 15.5% !important;
    }
  }
}
