body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: IBM Plex Sans, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #C8D5E1;
}

*::-webkit-scrollbar-thumb {
    background: #7F87A3;
}

.ant-pagination {
    font-family: IBM Plex Sans, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    margin-top: 10px!important;
    margin-bottom: 10px!important;
    line-height: 100%;
    color: #1B1B4E;
}

.ant-pagination .ant-pagination-item {
    min-width: 30px !important;
    background: #FFFFFF;
    color: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: none;
}

.ant-pagination .ant-pagination-item-active {
    background: #FFFFFF;
    box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: none;
}

.ant-pagination .ant-pagination-item a,
.ant-pagination .ant-pagination-item a:hover,
.ant-pagination .ant-pagination-item a:focus{
    color: black;
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active a:hover,
.ant-pagination .ant-pagination-item-active a:focus{
    color: black;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
    width: 30px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-repeat: no-repeat;
    border: none;
    background-position: center; /* Center the image */
    background-image: url("./images/prevIcon.jpg");
}

.ant-pagination .ant-pagination-next .ant-pagination-item-link {
    width: 30px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-repeat: no-repeat;
    border: none;
    background-position: center; /* Center the image */
    background-image: url("./images/nextIcon.jpg");
}

.ant-pagination .ant-pagination-prev svg, .ant-pagination .ant-pagination-next svg {
    display: none;
}

rect {
    rx: 7px;
}

.bossMainChartCont g g g text{
    font-size: 1.3rem !important;
    font-family: Inter,serif !important;
}

.complexDonutChart g g g text{
    font-size: 1.6rem !important;
    font-family: Inter,serif !important;
}

.bossHousesChartCont g g g text{
    font-size: 1.1rem !important;
    font-family: Inter,serif !important;
}

.crmDonutChart g g g text{
    font-size: 0.8rem !important;
    font-family: Inter,serif !important;
}

g g g text{
    fill: #525274 !important;
    font-family: Inter,serif !important;
}

.YuridikShFamCont .ant-select-selector {
    border: none !important;
}