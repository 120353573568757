.actFormCont {
  display: flex;
  width: 100%;
}

.actLabel {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  width: 20%;
  line-height: 100%;
}

.actTextArea {
  width: 80%;
}

.actInput {
  width: 57%;
}

.actSwitch {
  width: 23%;
  text-align: end;
}

.fileEditButton {
  width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  color: #fff;
  border-radius: 7px;
  transition: 0.2s all ease;
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
  background-color: #096dd9;
}
.fileEditButton .ant-spin-dot-item {
  background-color: #fff;
}

.fileEditButton:hover {
  background-color: #40a9ff;
}

.listOfFiles {
  display: flex;
  flex-wrap: wrap;
  width: 78%;
}

.fileItem {
  user-select: none;
  margin: 0 5px 5px 5px;
  padding: 0 10px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-family: IBM Plex Sans, serif;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 100%;
  text-align: center;
  background: #dddddd;
  border-radius: 50px;
}

.footerCont {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.actInfoCont {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.actInfoTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  width: 20%;
  line-height: 100%;
}

.actInfo {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-size: 1rem;
  width: 80%;
  line-height: 100%;
}

.actInfoFileTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  width: 32%;
  line-height: 100%;
}

.actInfoFile {
  font-family: IBM Plex Sans, serif;
  display: flex;
  font-style: normal;
  font-size: 1rem;
  line-height: 100%;
}

.actInfoFileName {
  width: 250px;
}

.actFileDownloadIcon {
  font-size: 1.4rem;
  color: #494646;
  cursor: pointer;
}

.fieldText {
  padding: 0 1rem;
  margin-bottom: 0 !important;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 0;
  margin-top: -0.5rem;
}
.imgBox {
  position: relative;
}
.creditCardCol {
  display: flex;
  flex-direction: column;

  /* width: 100%;
  height: 100%; */
}
.cardItem {
  font-family: monospace;
  position: absolute;
  color: #fff;
}
.cardTitle {
  top: 18px;
  left: 30px;
  font-size: 1.75rem;
}
.cardNumber {
  top: 148px;
  left: 42px;
  font-size: 1.75rem;
}
.cardDate {
  top: 195px;
  left: 42px;
  font-size: 1rem;
  color: white;
}

.partsModal {
  .ant-modal-body {
    padding: 0 24px;
  }
  .productCont {
    margin: 0;
    width: 100%;
    .ant-form {
      padding: 10px;
    }
    .ant-row {
      margin-top: 0;
    }
  }
}

.sponsor_cont {
  .ant-tabs-tab-disabled {
    cursor: auto;
  }
}
