.ant-tooltip {
  z-index: 0;
}

.ant-tooltip-inner,
.ant-tooltip-arrow-content {
  background-color: #1890ff;
}

body {
  background-color: #fff;
}

tbody tr {
  cursor: pointer;
}

.df {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.ai-c {
  align-items: center;
}

.jc-sa {
  justify-content: space-around;
}

.m0 {
  margin: 0;
}

.mb0 {
  margin-bottom: 0 !important;
}

.bd-n {
  border: none !important;
}

.cur-p {
  cursor: pointer;
}

.ta-l {
  text-align: left;
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/IBMPlexSans-Bold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/IBMPlexSans-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/IBMPlexSans-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/IBMPlexSans-Light.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/IBMPlexSans-ExtraLight.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/IBMPlexSans-Thin.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/IBMPlexMono-Bold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/IBMPlexMono-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/IBMPlexMono-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/IBMPlexMono-Light.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/IBMPlexMono-ExtraLight.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/IBMPlexMono-Thin.ttf'); /* IE9 Compat Modes */
}

.sidebar {
  position: fixed;
  width: 221px;
  min-height: 100%;
  background: #ffffff;
  left: 0;
  top: 0;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.05);
  text-align: center;
  z-index: 99;
}

.sidebarMenuButton {
  border-radius: 6px;
  width: 160px;
  height: 32px;
  background: #fff;
  color: #989fb9;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  border: 0;
  box-shadow: none;
  text-shadow: none;
  text-align: left;
}

.sidebarMenuButton:hover,
.sidebarMenuButton:focus,
.sidebarMenuButtonActive {
  background: #f9f9fa;
  color: #434a59;
  border: 0;
}

.smbuttonIcon {
  margin-right: 10px;
}

.smbuttonIcon:hover,
.smbuttonIconActive {
  color: #7944e3;
}

.smPlusButton {
  right: -16px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 0.5px dashed #687191;
  box-sizing: border-box;
  border-radius: 6px;
  filter: drop-shadow(0px 0 7px rgba(0, 0, 0, 0.08));
}

.smPlusButton:hover,
.smPlusButton:focus,
.smPlusButtonActive {
  background: #7944e3;
  border-color: #ffffff;
  color: white;
}

.smActionButton,
.smActionButton:focus,
.smActionButton:hover {
  right: -16px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #7944e3;
  border: 0.5px dashed white;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0;
  filter: drop-shadow(0px 0 7px rgba(0, 0, 0, 0.08));
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}

.logoutButton {
  right: -16px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 0.5px dashed #687191;
  color: #687191;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0;
  filter: drop-shadow(0px 0 7px rgba(0, 0, 0, 0.08));
}

.logoutButton:hover,
.logoutButton:focus {
  background: #7944e3;
  border-color: white;
  color: white;
}

.sidebarTop {
  margin-top: 15px;
  margin-bottom: 20px;
}

.sidebarTopLogo {
  width: 60px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.sidebarTopLogoDev {
  width: 10vh;
  position: relative;
  display: inline-block;
}

.sidebarTopTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  color: #989fb9;
  position: relative;
  display: inline-block;
  text-align: left;
}

.sidebarBottomUserName {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #989fb9;
}

.sidebarBottomUserRole {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  color: #989fb9;
}

.sidebarBottom {
  bottom: 20px;
  position: absolute;
  text-align: center;
  margin: auto;
  width: 100%;
}

.sidebarBottomAppVersion {
  margin-top: 15px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #989fb9;
}

.fixedFooter {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 255px;
  text-align: center;
}

.headerTools {
  background-color: #fafbfd;
}

.circle-input {
  border-radius: 50px;
  border: 1px solid #dcd8d8;
  padding: 2px 10px;
}

.circle-input:hover {
  border: 1px solid rgba(101, 141, 233, 0.6);
}

.circle-input:focus {
  border: 1px solid rgba(101, 141, 233, 0.6);
  box-shadow: 0 0 3px rgba(101, 141, 233, 0.6);
}

.bbCardTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
}

.bbCardDescr {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.filterFieldTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  color: #989fb9;
}

.homePageScrollClass {
  height: 85vh;
  width: 101%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.homePageScrollClass::-webkit-scrollbar {
  width: 8px;
}

.homePageScrollClass::-webkit-scrollbar-track {
  background: #c8d5e1;
}

.homePageScrollClass::-webkit-scrollbar-thumb {
  background: #7f87a3;
}

/*
//////////////////////// ContractCont \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.headerClass {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto 20px auto;
}

.headerClass1 {
  position: relative;
  width: 56px;
  height: 56px;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.4s all ease;
  cursor: pointer;
  border: 1px dashed #434a59;
}

.headerClass1:hover {
  background-color: #ffe500;
  border-color: #ffe500;
}

.Contractactive {
  position: relative;
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transition: 0.4s all ease;
  border: 1px solid transparent;
  background-color: #ffe500;
}

.iconClassClient {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.spanClass {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-left: 18px;
  text-align: left;
  cursor: default;
  flex: 2;
}

.spanClass::after {
  margin-left: 10px;
  font-weight: 400;
  width: 70px;
  /* //content: '-----'; */
}

.spanClass:nth-last-child(1)::after {
  content: '';
  margin-left: 0;
  font-weight: 0;
  width: 0;
}

.CardRowClass {
  margin-left: 30px;
  width: 867px;
  height: 94px;
  margin-right: 125px;
  display: block;
  background: #fafbfd;
  border: 0.5px dashed #989fb9;
  box-sizing: border-box;
  border-radius: 6px;
}

.DeleteCardButton {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 50px;
  top: 122px;

  cursor: pointer;
  background: #fafbfd;
  border: 0.5px dashed #989fb9;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: 0.3s all ease;
  border-radius: 6px;
}

.DeleteCardButton:hover {
  background: #eceff5;
}

.DeleteCardButtonIcon {
  position: absolute;
  color: #989fb9;
  font-size: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nextPageIconContractPlus {
  position: fixed;
  right: 40px;
  top: 300px;
  color: #ffe500;
  font-size: 1.5rem;
}

/*
////////////////////////////////// Company Shaxs Input \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
/////////////////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.YuridikShFamCont {
  display: block;
  width: 800px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px;
  margin: 15px auto;
}

.YuridikShLabel {
  display: inline-block;
  text-align: right;
  min-width: 170px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  color: #989fb9;
}

.YuridikErrorClass {
  font-family: IBM Plex Sans, serif;
  color: red;
  margin-left: 20px;
}

.YuridikAddButton {
  position: relative;
  display: inline-block;
  text-align: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 25px;
  border: 0.5px dashed #989fb9;
  border-radius: 4px;
}

.YuridikRemoveButton {
  position: relative;
  display: inline-block;
  text-align: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 25px;
  border: 0.5px dashed #989fb9;
  border-radius: 4px;
}

.cliendAddDiv {
  display: flex;
  outline-style: none;
  border: none;
  border-radius: 7px;
  background-color: rgb(250, 251, 253);
  height: 78px;
  width: 384px;
  align-items: center;
  cursor: pointer;
  transition: all ease-out 0.3s;
}

.cliendAddDiv:hover {
  background-color: rgb(224, 219, 219);
}

.cliendAddDiv:focus {
  border: none;
}

.cliendAddDiv:hover .clientAdd {
  background-color: rgb(224, 219, 219);
}

.clientAdd {
  position: relative;
  width: 34px;
  height: 34px;
  margin: 0 20px;
  overflow: hidden;

  background: #fafbfd;
  outline-style: none;
  border: 0.5px dashed #989fb9;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  transition: all ease 0.4s;
}

.YuridikCard {
  position: relative;
  width: 384px;
  height: 78px;
  background: #ffffff;
  cursor: pointer;
  text-align: left;
  outline-style: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 15px 0;
  padding: 10px 15px;
}

.YuridikCard:hover {
  background: #ffe500;
}

.personCard {
  position: relative;
  min-height: 70px;
  background: #ffffff;
  cursor: pointer;
  text-align: left;
  outline-style: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 15px 0;
  padding: 10px 5px;
}

.personCard:hover {
  background: #ffe500;
}

.YuridikPersonClick {
  position: absolute;
  top: 50%;
  transform: translate(-100%, -50%);
  width: 30px;
  height: 30px;
  background: #ffe500;
  color: #f7f8fa !important;
  border: 3px solid #fafbfd;
  box-sizing: border-box;
  border-radius: 50%;
}

.CardBirthdayDate {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background: #ffe500;
  cursor: pointer;
  outline-style: none;
  border: none;
}

.houseCardStyle {
  position: relative;
  display: block;
  width: 384px;
  height: 78px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 15px 0 15px 15px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.houseCardStyle:hover {
  background-color: #ffe500;
}

@media only screen and (max-width: 1150px) {
  .CardRowClass {
    margin-left: 0;
    width: 650px;
  }

  .DeleteCardButton {
    right: 5px;
  }
}

@media only screen and (max-width: 1250px) {
  .CardRowClass {
    margin-left: 0;
    width: 800px;
  }

  .DeleteCardButton {
    right: 10px;
  }
}

.clientTypeLabel {
  margin-left: 15%;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #989fb9;
}

@media only screen and (min-width: 1450px) {
  .headerClass {
    margin-left: auto;
    margin-right: auto;
    /* //width: 1400px; */
  }

  .CardRowClass {
    margin-left: auto;
    margin-right: auto !important;
  }

  .DeleteCardButton {
    right: 110px;
  }

  /* // .spanClass::after {
    //   //content: '---------';
    // } */
  .clientTypeLabel {
    margin-left: 25%;
  }
}

.ant-input[disabled] {
  color: #000;
  background-color: #fff;
  opacity: 1;
}

.ant-picker.ant-picker-disabled {
  background: #fff;
  color: #000;
  cursor: default !important;
}

input:disabled {
  background-color: #fff;
  color: #000;
}

.ant-picker-input > input[disabled] {
  color: #000;
  background-color: #fff;
  cursor: default;
}

/* ////////////////////////////////////******** Client Page ************\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.ClientFiltrButton {
  position: relative;
  width: 136px;
  height: 38px;
  outline-style: none;
  border: 1px solid #ced1dc;
  box-sizing: border-box;
  border-radius: 19px;
  background-color: #fafbfd;
  transition: all ease-out 0.3s;
  cursor: pointer;
}

.ClientFiltrButton:hover {
  background-color: #7944e3;
  color: #fff;
}

.ClientFiltrButton.active {
  background-color: #7944e3;
  color: #fff;
}

.ClientResetButton {
  width: 43px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #ced1dc;
  box-sizing: border-box;
  border-radius: 11px;
}

.ClientFilterLabel {
  display: inline-block;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  color: #989fb9;
  margin-bottom: 10px;
}

.ClientPhoneInput {
  width: 153px;
  height: 32px;
  padding: 0 4px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline-style: none;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  transition: all ease 0.3s;
  color: #434a59;
}

.ClientPhoneInput:hover {
  border-color: #40a9ff;
}

.ClientPhoneInput:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
}

.ClientSelectorArea {
  width: 153px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  text-align: right;
  color: #434a59;
}

.ClientSelectorArea .ant-select-selector {
  background: none !important;
}

.ClientSelectorStatus {
  width: 153px;
  min-height: 32px;
  background: #ffffff;
  border-radius: 4px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  text-align: right;
  color: #434a59;
}

.ClientSelectorStatus .ant-select-selector {
  background: none !important;
}

.ClientTableIcon {
  display: flex;
  width: 42px;
  height: 42px;
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ease 0.3s;
  color: #434a59;
  cursor: pointer;
  margin: 0 auto;
}

.ClientTableIcon:hover {
  background-color: rgb(223, 223, 223, 0.5);
  /* color: #c4c4c4; */
}

/* //////////////////////////////////////////// **** Bron *** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.BronModalColName {
  display: inline-block;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  color: #434a59;
  width: 80px;
  margin-top: 10px;
  text-align: right;
}

.BronModalColInfo {
  display: inline-block;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-size: 15px;
  line-height: 100%;
  color: #434a59;
  padding-left: 10px;
}

.BronAlertColRightName {
  display: inline-block;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  color: #434a59;
}

.BronModalButtons {
  display: flex;
  align-items: center;
  text-align: center;
  width: 150px;
  height: 66px;
  background: #7944e3;
  border-radius: 6px;
  transition: all ease-out 0.3s;
  cursor: pointer;
}

.BronModalButtonsInfo {
  padding-left: 30px;
  display: block;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
}

.BronModalButtons:hover {
  background: #e4daf9;
}

.BronModalButtons:hover .BronModalButtonsInfo {
  color: #7944e3;
}

.editable-row {
  cursor: default;
}

.BronTableMenu:hover {
  background-color: #875fd6;
}

.BronTableLittleMenu {
  color: #fff;
}

/* ///////////////////////////////////////// **Contract** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.AktivStatus0 {
  background: #fff;
  border-radius: 6px;
}

.AktivStatus1 {
  background: linear-gradient(90deg, #c9ffd8 1%, #ffffff 1%);
  border-radius: 6px;
}

.AktivStatus2 {
  background: linear-gradient(90deg, #c9ffd8 2%, #ffffff 2%);
  border-radius: 6px;
}

.AktivStatus3 {
  background: linear-gradient(90deg, #c9ffd8 3%, #ffffff 3%);
  border-radius: 6px;
}

.AktivStatus4 {
  background: linear-gradient(90deg, #c9ffd8 4%, #ffffff 4%);
  border-radius: 6px;
}

.AktivStatus5 {
  background: linear-gradient(90deg, #c9ffd8 5%, #ffffff 5%);
  border-radius: 6px;
}

.AktivStatus6 {
  background: linear-gradient(90deg, #c9ffd8 6%, #ffffff 6%);
  border-radius: 6px;
}

.AktivStatus7 {
  background: linear-gradient(90deg, #c9ffd8 7%, #ffffff 7%);
  border-radius: 6px;
}

.AktivStatus8 {
  background: linear-gradient(90deg, #c9ffd8 8%, #ffffff 8%);
  border-radius: 6px;
}

.AktivStatus9 {
  background: linear-gradient(90deg, #c9ffd8 9%, #ffffff 9%);
  border-radius: 6px;
}

.AktivStatus10 {
  background: linear-gradient(90deg, #c9ffd8 10%, #ffffff 10%);
  border-radius: 6px;
}

.AktivStatus11 {
  background: linear-gradient(90deg, #c9ffd8 11%, #ffffff 11%);
  border-radius: 6px;
}

.AktivStatus12 {
  background: linear-gradient(90deg, #c9ffd8 12%, #ffffff 12%);
  border-radius: 6px;
}

.AktivStatus13 {
  background: linear-gradient(90deg, #c9ffd8 13%, #ffffff 13%);
  border-radius: 6px;
}

.AktivStatus14 {
  background: linear-gradient(90deg, #c9ffd8 14%, #ffffff 14%);
  border-radius: 6px;
}

.AktivStatus15 {
  background: linear-gradient(90deg, #c9ffd8 15%, #ffffff 15%);
  border-radius: 6px;
}

.AktivStatus16 {
  background: linear-gradient(90deg, #c9ffd8 16%, #ffffff 16%);
  border-radius: 6px;
}

.AktivStatus17 {
  background: linear-gradient(90deg, #c9ffd8 17%, #ffffff 17%);
  border-radius: 6px;
}

.AktivStatus18 {
  background: linear-gradient(90deg, #c9ffd8 18%, #ffffff 18%);
  border-radius: 6px;
}

.AktivStatus19 {
  background: linear-gradient(90deg, #c9ffd8 19%, #ffffff 19%);
  border-radius: 6px;
}

.AktivStatus20 {
  background: linear-gradient(90deg, #c9ffd8 20%, #ffffff 20%);
  border-radius: 6px;
}

.AktivStatus21 {
  background: linear-gradient(90deg, #c9ffd8 21%, #ffffff 21%);
  border-radius: 6px;
}

.AktivStatus22 {
  background: linear-gradient(90deg, #c9ffd8 22%, #ffffff 22%);
  border-radius: 6px;
}

.AktivStatus23 {
  background: linear-gradient(90deg, #c9ffd8 23%, #ffffff 23%);
  border-radius: 6px;
}

.AktivStatus24 {
  background: linear-gradient(90deg, #c9ffd8 24%, #ffffff 24%);
  border-radius: 6px;
}

.AktivStatus25 {
  background: linear-gradient(90deg, #c9ffd8 25%, #ffffff 25%);
  border-radius: 6px;
}

.AktivStatus26 {
  background: linear-gradient(90deg, #c9ffd8 26%, #ffffff 26%);
  border-radius: 6px;
}

.AktivStatus27 {
  background: linear-gradient(90deg, #c9ffd8 27%, #ffffff 27%);
  border-radius: 6px;
}

.AktivStatus28 {
  background: linear-gradient(90deg, #c9ffd8 28%, #ffffff 28%);
  border-radius: 6px;
}

.AktivStatus29 {
  background: linear-gradient(90deg, #c9ffd8 29%, #ffffff 29%);
  border-radius: 6px;
}

.AktivStatus30 {
  background: linear-gradient(90deg, #c9ffd8 30%, #ffffff 30%);
  border-radius: 6px;
}

.AktivStatus31 {
  background: linear-gradient(90deg, #c9ffd8 31%, #ffffff 31%);
  border-radius: 6px;
}

.AktivStatus32 {
  background: linear-gradient(90deg, #c9ffd8 32%, #ffffff 32%);
  border-radius: 6px;
}

.AktivStatus33 {
  background: linear-gradient(90deg, #c9ffd8 33%, #ffffff 33%);
  border-radius: 6px;
}

.AktivStatus34 {
  background: linear-gradient(90deg, #c9ffd8 34%, #ffffff 34%);
  border-radius: 6px;
}

.AktivStatus35 {
  background: linear-gradient(90deg, #c9ffd8 35%, #ffffff 35%);
  border-radius: 6px;
}

.AktivStatus36 {
  background: linear-gradient(90deg, #c9ffd8 36%, #ffffff 36%);
  border-radius: 6px;
}

.AktivStatus37 {
  background: linear-gradient(90deg, #c9ffd8 37%, #ffffff 37%);
  border-radius: 6px;
}

.AktivStatus38 {
  background: linear-gradient(90deg, #c9ffd8 38%, #ffffff 38%);
  border-radius: 6px;
}

.AktivStatus39 {
  background: linear-gradient(90deg, #c9ffd8 39%, #ffffff 39%);
  border-radius: 6px;
}

.AktivStatus40 {
  background: linear-gradient(90deg, #c9ffd8 40%, #ffffff 40%);
  border-radius: 6px;
}

.AktivStatus41 {
  background: linear-gradient(90deg, #c9ffd8 41%, #ffffff 41%);
  border-radius: 6px;
}

.AktivStatus42 {
  background: linear-gradient(90deg, #c9ffd8 42%, #ffffff 42%);
  border-radius: 6px;
}

.AktivStatus43 {
  background: linear-gradient(90deg, #c9ffd8 43%, #ffffff 43%);
  border-radius: 6px;
}

.AktivStatus44 {
  background: linear-gradient(90deg, #c9ffd8 44%, #ffffff 44%);
  border-radius: 6px;
}

.AktivStatus45 {
  background: linear-gradient(90deg, #c9ffd8 45%, #ffffff 45%);
  border-radius: 6px;
}

.AktivStatus46 {
  background: linear-gradient(90deg, #c9ffd8 46%, #ffffff 46%);
  border-radius: 6px;
}

.AktivStatus47 {
  background: linear-gradient(90deg, #c9ffd8 47%, #ffffff 47%);
  border-radius: 6px;
}

.AktivStatus48 {
  background: linear-gradient(90deg, #c9ffd8 48%, #ffffff 48%);
  border-radius: 6px;
}

.AktivStatus49 {
  background: linear-gradient(90deg, #c9ffd8 49%, #ffffff 49%);
  border-radius: 6px;
}

.AktivStatus50 {
  background: linear-gradient(90deg, #c9ffd8 50%, #ffffff 50%);
  border-radius: 6px;
}

.AktivStatus51 {
  background: linear-gradient(90deg, #c9ffd8 51%, #ffffff 51%);
  border-radius: 6px;
}

.AktivStatus52 {
  background: linear-gradient(90deg, #c9ffd8 52%, #ffffff 52%);
  border-radius: 6px;
}

.AktivStatus53 {
  background: linear-gradient(90deg, #c9ffd8 53%, #ffffff 53%);
  border-radius: 6px;
}

.AktivStatus54 {
  background: linear-gradient(90deg, #c9ffd8 54%, #ffffff 54%);
  border-radius: 6px;
}

.AktivStatus55 {
  background: linear-gradient(90deg, #c9ffd8 55%, #ffffff 55%);
  border-radius: 6px;
}

.AktivStatus56 {
  background: linear-gradient(90deg, #c9ffd8 56%, #ffffff 56%);
  border-radius: 6px;
}

.AktivStatus57 {
  background: linear-gradient(90deg, #c9ffd8 57%, #ffffff 57%);
  border-radius: 6px;
}

.AktivStatus58 {
  background: linear-gradient(90deg, #c9ffd8 58%, #ffffff 58%);
  border-radius: 6px;
}

.AktivStatus59 {
  background: linear-gradient(90deg, #c9ffd8 59%, #ffffff 59%);
  border-radius: 6px;
}

.AktivStatus60 {
  background: linear-gradient(90deg, #c9ffd8 60%, #ffffff 60%);
  border-radius: 6px;
}

.AktivStatus61 {
  background: linear-gradient(90deg, #c9ffd8 61%, #ffffff 61%);
  border-radius: 6px;
}

.AktivStatus62 {
  background: linear-gradient(90deg, #c9ffd8 62%, #ffffff 62%);
  border-radius: 6px;
}

.AktivStatus63 {
  background: linear-gradient(90deg, #c9ffd8 63%, #ffffff 63%);
  border-radius: 6px;
}

.AktivStatus64 {
  background: linear-gradient(90deg, #c9ffd8 64%, #ffffff 64%);
  border-radius: 6px;
}

.AktivStatus65 {
  background: linear-gradient(90deg, #c9ffd8 65%, #ffffff 65%);
  border-radius: 6px;
}

.AktivStatus66 {
  background: linear-gradient(90deg, #c9ffd8 66%, #ffffff 66%);
  border-radius: 6px;
}

.AktivStatus67 {
  background: linear-gradient(90deg, #c9ffd8 67%, #ffffff 67%);
  border-radius: 6px;
}

.AktivStatus68 {
  background: linear-gradient(90deg, #c9ffd8 68%, #ffffff 68%);
  border-radius: 6px;
}

.AktivStatus69 {
  background: linear-gradient(90deg, #c9ffd8 69%, #ffffff 69%);
  border-radius: 6px;
}

.AktivStatus70 {
  background: linear-gradient(90deg, #c9ffd8 70%, #ffffff 70%);
  border-radius: 6px;
}

.AktivStatus71 {
  background: linear-gradient(90deg, #c9ffd8 71%, #ffffff 71%);
  border-radius: 6px;
}

.AktivStatus72 {
  background: linear-gradient(90deg, #c9ffd8 72%, #ffffff 72%);
  border-radius: 6px;
}

.AktivStatus73 {
  background: linear-gradient(90deg, #c9ffd8 73%, #ffffff 73%);
  border-radius: 6px;
}

.AktivStatus74 {
  background: linear-gradient(90deg, #c9ffd8 74%, #ffffff 74%);
  border-radius: 6px;
}

.AktivStatus75 {
  background: linear-gradient(90deg, #c9ffd8 75%, #ffffff 75%);
  border-radius: 6px;
}

.AktivStatus76 {
  background: linear-gradient(90deg, #c9ffd8 76%, #ffffff 76%);
  border-radius: 6px;
}

.AktivStatus77 {
  background: linear-gradient(90deg, #c9ffd8 77%, #ffffff 77%);
  border-radius: 6px;
}

.AktivStatus78 {
  background: linear-gradient(90deg, #c9ffd8 78%, #ffffff 78%);
  border-radius: 6px;
}

.AktivStatus79 {
  background: linear-gradient(90deg, #c9ffd8 79%, #ffffff 79%);
  border-radius: 6px;
}

.AktivStatus80 {
  background: linear-gradient(90deg, #c9ffd8 80%, #ffffff 80%);
  border-radius: 6px;
}

.AktivStatus81 {
  background: linear-gradient(90deg, #c9ffd8 81%, #ffffff 81%);
  border-radius: 6px;
}

.AktivStatus82 {
  background: linear-gradient(90deg, #c9ffd8 82%, #ffffff 82%);
  border-radius: 6px;
}

.AktivStatus83 {
  background: linear-gradient(90deg, #c9ffd8 83%, #ffffff 83%);
  border-radius: 6px;
}

.AktivStatus84 {
  background: linear-gradient(90deg, #c9ffd8 84%, #ffffff 84%);
  border-radius: 6px;
}

.AktivStatus85 {
  background: linear-gradient(90deg, #c9ffd8 85%, #ffffff 85%);
  border-radius: 6px;
}

.AktivStatus86 {
  background: linear-gradient(90deg, #c9ffd8 86%, #ffffff 86%);
  border-radius: 6px;
}

.AktivStatus87 {
  background: linear-gradient(90deg, #c9ffd8 87%, #ffffff 87%);
  border-radius: 6px;
}

.AktivStatus88 {
  background: linear-gradient(90deg, #c9ffd8 88%, #ffffff 88%);
  border-radius: 6px;
}

.AktivStatus89 {
  background: linear-gradient(90deg, #c9ffd8 89%, #ffffff 89%);
  border-radius: 6px;
}

.AktivStatus90 {
  background: linear-gradient(90deg, #c9ffd8 90%, #ffffff 90%);
  border-radius: 6px;
}

.AktivStatus91 {
  background: linear-gradient(90deg, #c9ffd8 91%, #ffffff 91%);
  border-radius: 6px;
}

.AktivStatus92 {
  background: linear-gradient(90deg, #c9ffd8 92%, #ffffff 92%);
  border-radius: 6px;
}

.AktivStatus93 {
  background: linear-gradient(90deg, #c9ffd8 93%, #ffffff 93%);
  border-radius: 6px;
}

.AktivStatus94 {
  background: linear-gradient(90deg, #c9ffd8 94%, #ffffff 94%);
  border-radius: 6px;
}

.AktivStatus95 {
  background: linear-gradient(90deg, #c9ffd8 95%, #ffffff 95%);
  border-radius: 6px;
}

.AktivStatus96 {
  background: linear-gradient(90deg, #c9ffd8 96%, #ffffff 96%);
  border-radius: 6px;
}

.AktivStatus97 {
  background: linear-gradient(90deg, #c9ffd8 97%, #ffffff 97%);
  border-radius: 6px;
}

.AktivStatus98 {
  background: linear-gradient(90deg, #c9ffd8 98%, #ffffff 98%);
  border-radius: 6px;
}

.AktivStatus99 {
  background: linear-gradient(90deg, #c9ffd8 99%, #ffffff 99%);
  border-radius: 6px;
}

.AktivStatus100 {
  background: #c9ffd8;
  border-radius: 6px;
}

.noAktivStatus {
  cursor: default;
  border-radius: 6px;
}

/* ////////////////////////////////// *****ContractInfoPage***** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.contractInfoFlatInfoCard {
  width: 251px;
  height: 269px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.contractInfoClientCard {
  width: 90%;
  min-height: 276px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-bottom: 10px;
}

.contractInfoContractDate {
  width: 90%;
  min-height: 245px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-bottom: 10px;
}

.contractInfoContractCount {
  width: 377px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.oneContractLabelName {
  font-family: IBM Plex Sans, serif;
  display: inline-block;
  min-width: 180px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #989fb9;
}

.contractInfoPlatFlatButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100px;
  height: 40px;
  background: #e4daf9;
  border-radius: 6px;
  transition: all 0.3s ease-out;
  margin-top: 10px;
}

.contractInfoPlatFlatButton span,
.contractInfoPDFButton span,
.contractInfoCountGButton span {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #7944e3;
}

.contractInfoPlatFlatButton:hover,
.contractInfoPDFButton:hover,
.contractInfoCountGButton:hover {
  box-shadow: 0 0 10px 1px rgba(175, 79, 204, 0.75);
}

.contractInfoPlatFlatButton:focus,
.contractInfoPDFButton:focus,
.contractInfoCountGButton:focus {
  box-shadow: 0 0 10px 1px rgba(175, 79, 204, 0.75);
}

.contractInfoPDFButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 152px;
  height: 40px;
  margin-right: 20px;
  background: #e4daf9;
  border-radius: 6px;
  transition: all 0.3s ease-out;
  margin-top: 10px;
}

.contractInfoCountGButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 130px;
  height: 40px;
  background: #e4daf9;
  border-radius: 6px;
  transition: all 0.3s ease-out;
  margin-top: 10px;
}

.ContractInfoPrinterIconDiv {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 39px;
  margin-left: 15px;
  background: #7944e3;
  border-radius: 6px;
  cursor: pointer;
}

.ContractInfoPrinterIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff !important;
}

.productListCont {
  padding: 20px;
  width: 95%;
  margin: 5px 0;
  background: #ffffff;
  border-radius: 7px;
}

.productListLabel {
  font-family: IBM Plex Sans, serif;
  width: 35%;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #989fb9;
}

.productListName {
  width: 65%;
  text-align: right;
  display: inline-block;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  color: #434a59;
}

.ContractInfoHistoryTableRow {
  margin: 5px;
  cursor: default;
  border-radius: 6px;
}

.ContractInfoHistoryTableCountColumn {
  color: #000;
}

/* ////////////////////////////////////////////////// ****** HouseEdit ****************** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.FlatEditCardCont {
  background: #ffffff;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.05);
  margin-top: 15px;
}

.FlatEditImgClass {
  width: 190px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-top: 10px;
}

.FlatEditUploadImgButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20%;
  margin-top: 25px;
  width: 100px;
  height: 32px;
  font-size: 15px;
  background: #067be8;
  border: 1px solid #1b92ff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: all ease-out 0.4s;
}

.FlatEditUploadImgButton:hover {
  background-color: #fff;
  color: #067be8;
}

.FlatEditUploadImgIcon {
  margin-right: 5px;
}

/* /////////////////////////////////// ***Shaxmatka*** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.ShaxmatkaTableHouseUp {
  display: none;
  position: absolute;
  right: 5px;
  width: 143px;
  z-index: 2;
  background: #7944e3;
  border-radius: 5px;
  transition: all ease-in 0.3s;
}

.ShaxmatkaTableHouseUpItem {
  display: block;
  padding: 7px 7px 7px 10px;
  font-size: 1rem;
  color: #fff;
  width: 100%;
}

.ShaxmatkaTableHouseUpItem:hover {
  color: #fff;
  background-color: rgb(176, 50, 235);
  border-radius: 5px;
}

.ClientTableIconAktiv {
  display: flex;
  width: 42px;
  height: 42px;
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ease 0.3s;
  color: #c4c4c4;
  cursor: pointer;
}

.ClientTableIconAktiv:hover {
  background-color: rgb(223, 223, 223);
  color: #c4c4c4;
}

.ClientTableIconAktiv:hover .ShaxmatkaTableHouseUp {
  margin-top: 100px;
  display: block;
}

/* /////////////////////////////////// *******EditBuilding********** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.headerEdingBuildingItem {
  display: flex;
  align-items: center;
  margin-right: 17px;
  width: 288px;
  padding: 5px 10px;
  background: #f0f2f8;
  border-radius: 13px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  cursor: default;
  color: #9c9c9c;
}

.EditBuildingButton {
  display: flex;
  align-items: center;
  width: 154px;
  height: 257px;
  background: #fafbfd;
  border: 1px dashed #9c9c9c;
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.EditBuildingButton:hover {
  background-color: #fff;
}

.EditBuildingButtonIcon {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  color: #9c9c9c;
  width: 154px;
  display: block;
  text-align: center;
  font-size: 2.5rem;
}

.AddBuildingButtonMenuStyle {
  position: relative;
  width: 85%;
  padding-bottom: 8px;
  z-index: 1000;
  transform: translate(10px, 110px);
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.AddBuildingButtonMenuStyleMiniKub {
  position: absolute;
  background: #ffffff;
  border: 8px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  left: 45%;
  bottom: -8%;
}

.AddBuildingButtonMenuStyleButton {
  margin-top: 5px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  color: #989fb9;
  display: flex;
  margin-left: auto;
  width: 45px;
  margin-right: 5px;
  border-bottom: 1px solid transparent;
  transition: all ease-in 0.4s;
  cursor: pointer;
}

.AddBuildingButtonMenuStyleButtonOk {
  margin-top: 5px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  color: #505050;
  display: flex;
  width: 20px;
  transition: all ease-in 0.4s;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.AddBuildingButtonMenuStyleButton:hover,
.AddBuildingButtonMenuStyleButtonOk:hover {
  border-bottom: 1px solid #989fb9;
}

.FlatContextMenu {
  user-select: none;
  width: 179px;
  background: #222222;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  color: #fff;
}

.FlatContextMenuItem {
  cursor: pointer;
  font-family: IBM Plex Mono, serif;
  font-style: normal;
  font-weight: 100;
  line-height: 100%;
  padding: 3px 0 0 15px;
  margin: 10px 0;
  font-size: 14px;
}

.FlatContextMenuItem:hover {
  background-color: #18a0fb;
}

.SelectAllItemsClass {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  width: 21px;
  height: 21px;
  color: #fff;
  background: #687190;
  border-radius: 6px;
}

.BlockNameClass {
  cursor: default;
  height: 18px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #989fb9;
}

.BlockContextMenu {
  width: 179px;
  background: #222222;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  color: #fff;
}

.nonSelectable {
  user-select: none;
}

.ui-selectable {
  background-color: #fff;
  border: 1px solid transparent;
}

.ui-selecting {
  border: 1px solid #ff2f90;
}

.ui-selected {
  border: 1px solid #8a2fff;
}

.non-selectable {
  background-color: #c0c0c0;
  border: 1px solid transparent;
}

.ui-bron {
  background-color: #81bfff;
  border: 1px solid transparent;
}

.ui-sold {
  background-color: #51ff77;
  border: 1px solid transparent;
}

/* /////////////////// *************BlockMenuRight***************** \\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.BlockMenuRightClass {
  position: fixed;
  overflow: auto;
  right: 0;
  padding-top: 90px;
  width: 250px;
  height: 100vh;
  background: #ffffff;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.05);
  animation: OpenAnimation 0.1s ease-in-out 1;
}

.BlockMenuRightClassClose {
  position: fixed;
  padding-top: 90px;
  overflow: auto;
  right: -250px;
  width: 250px;
  height: 100vh;
  background: #ffffff;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.05);
  animation: CloseAnimation 0.1s ease-in-out 1;
}

.BlockMenuOpenButton {
  position: fixed;
  z-index: 10;
  right: 230px;
  top: 30px;
}

.BlockMenuExitButtonIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
}

.BlockMenuExitButtonIconClose {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  animation: CloseIcon 0.7s linear 1;
}

.UpdateTimerClass {
  position: absolute;
  z-index: 10;
  right: 100px;
  top: 65px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #8f8f8f;
}

.BlockMenuInputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.BlockMenuInputLabel {
  width: 50%;
  text-align: right;
  padding-right: 5px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #989fb9;
}

.BlockMenuEditBlock {
  width: 100%;
  text-align: center;
  padding-right: 5px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #989fb9;
}

.BlockMenuInputInput {
  width: 50%;
  padding: 0 5px;
}

@keyframes OpenAnimation {
  0% {
    right: -250px;
  }
  100% {
    right: 0;
  }
}

@keyframes CloseAnimation {
  0% {
    right: 0;
  }
  100% {
    right: -250px;
  }
}

@keyframes CloseIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.SelectContScrollClass::-webkit-scrollbar {
  width: 8px;
}

.SelectContScrollClass::-webkit-scrollbar-track {
  background: #c8d5e1;
}

.SelectContScrollClass::-webkit-scrollbar-thumb {
  background: #7f87a3;
}

.ant-select.BlockMenuInputInput.ant-select-multiple.ant-select-allow-clear.ant-select-show-search {
  padding: 0;
}

.oneContractLabel {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #434a59;
  padding: 8px;
}

.ClientAddHeader {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 100%;
  display: block;
  margin: 10px 0;
  text-align: center;
  color: #989fb9;
  cursor: default;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.builderLabel {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 70px;
  line-height: 100%;
  text-align: center;
  color: #989fb9;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

/* ////////////////                   bossPage                 \\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.sidebarMenuButtonBoss {
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgb(51, 42, 124);
  color: #fff;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  border: 0;
  padding: 0.5vh;
  box-shadow: none;
  text-shadow: none;
}

.sidebarMenuButtonBoss:hover,
.sidebarMenuButtonBoss:focus,
.sidebarMenuButtonActiveBoss {
  background: #f9f9fa;
  color: rgb(51, 42, 124);
  border: 0;
}

.smbuttonIconBoss {
  margin-right: 10px;
}

.smbuttonIconBoss:hover,
.smbuttonIconActiveBoss {
  color: rgb(51, 42, 124);
}

/* //////////////////////////////////////////// bossMain \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.bossMainHeaderHouse {
  min-width: 10vw;
  background: rgb(103, 210, 224);
  box-shadow: 0 4px 10px rgba(103, 210, 224, 0.3);
  border-radius: 12px;
  padding: 25px 5px;
}

.bossMainHeaderHouseTitle {
  font-family: Inter, serif;
  padding: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.bossMainHeaderHouseCount {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.bossMainHeaderFlat {
  margin-left: 2vw;
  padding: 25px 5px;
  min-width: 10vw;
  background: rgb(255, 177, 41);
  box-shadow: 0 4px 10px rgba(255, 177, 41, 0.3);
  border-radius: 12px;
}

.bossMainHeaderClientBalance {
  padding: 25px 5px;
  margin-left: 2vw;
  min-width: 16vw;
  /* //height: 112px; */
  background: #b7e05e;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.15);
  border-radius: 12px;
}

.bossMainHeaderMonthlyDebtor {
  padding: 25px 5px;
  margin-left: 2vw;
  min-width: 18vw;
  /* //height: 112px; */
  background: #c74173;
  box-shadow: 0 4px 10px rgba(199, 65, 115, 0.3);
  border-radius: 12px;
}

.bossMainHeaderDebtor {
  padding: 25px 5px;
  margin-left: 2vw;
  min-width: 24vw;
  background: rgb(230, 92, 99);
  box-shadow: 0 4px 10px rgba(230, 92, 99, 0.3);
  border-radius: 12px;
}

.bossMainChartCont {
  position: relative;
  width: 675px;
  height: 337px;
  background: #f9f6ff;
  border-radius: 12px;
}

.bossMainChartTitle {
  margin-left: 20px;
  margin-top: 20px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  color: #525274;
}

.bossMainChartMiniTitle {
  margin-left: 23px;
  margin-bottom: 40px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: rgba(82, 82, 116, 0.79);
}

.bossMainChartTypePaymentCont {
  padding: 10px;
  display: flex;
  align-items: center;
}

.bossMainChartTypePaymentSpan1 {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #1f77b4;
  box-shadow: 0 4px 10px rgba(103, 210, 224, 0.3);
  border-radius: 11px;
}

.bossMainChartTypePaymentSpan2 {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #ff7f0e;
  box-shadow: 0 4px 10px rgba(255, 177, 41, 0.3);
  border-radius: 11px;
}

.bossMainChartTypePaymentSpan3 {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #2ca02c;
  box-shadow: 0 4px 10px rgba(230, 92, 98, 0.3);
  border-radius: 11px;
}

.bossMainChartTypePaymentSpan4 {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #d62728;
  box-shadow: 0 4px 10px rgba(155, 117, 236, 0.3);
  border-radius: 11px;
}

.bossMainChartTypePaymentSpanDisable {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #6c6c68;
  box-shadow: 0 4px 10px rgb(0, 0, 0);
  border-radius: 11px;
}

.bossMainChartTypePayment {
  padding-left: 10px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  color: #525274;
}

.bossMainRightSec {
  margin-left: 11.5vw;
  width: 328px;
  height: 337px;
  background: #f9f6ff;
  border-radius: 12px;
}

.bossMainRigtSecIconsCont {
  margin: 30px 0;
}

.bossMainRigtSecIcons {
  transform: translateX(-50%);
  position: relative;
  margin: 10px 0;
  width: 76px;
  height: 76px;
  border-radius: 11px;
}

.bossMainRigtSecIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #fff;
}

.bossMainRigtInfoCont {
  margin: 30px 100px;
  width: 250px;
}

.bossMainRigtInfoNumber {
  font-family: Inter, serif;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  width: 250px;
  font-size: 30px;
  line-height: 100%;
  color: #525274;
}

.bossMainRigtInfo {
  font-family: Inter, serif;
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 250px;
  line-height: 100%;
  color: #525274;
}

.messageBody {
  font-family: Inter, serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  color: #7d7d81;
  cursor: default;
}

.clientInfoInsideModal {
  margin: 10px 0;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #1d1f1e;
  cursor: default;
}

.bossPaymentButtonsDate {
  transition: all ease-out 0.3s;
  margin: 5px;
  width: 117px;
  height: 43px;
  background: #e7e8ee;
  border-radius: 11px;
  color: #525274;
  cursor: pointer;
}

.bossPaymentButtonsDateLabel {
  margin-top: 8px;
  display: block;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
}

.bossPaymentButtonsDate:hover {
  background: #332a7c;
  color: rgba(255, 255, 255, 0.9);
}

/* ////////////////////////////// BossHouses  \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

.bossHousesInfoFlats {
  display: flex;
  align-items: center;
  width: 196px;
  height: 132px;
  background: #f9f6ff;
  border-radius: 12px;
}

.bossHousesInfoFlatsIconsCont {
  transform: translateX(-34px);
  position: relative;
  width: 65px;
  height: 65px;
  background: #e65c62;
  box-shadow: 0 4px 10px rgba(230, 92, 98, 0.3);
  border-radius: 11px;
}

.bossHousesInfoFlatsIconsContBron {
  transform: translateX(-34px);
  position: relative;
  width: 65px;
  height: 65px;
  background: #67d2e0;
  box-shadow: 0 4px 10px rgba(103, 210, 224, 0.3);
  border-radius: 11px;
}

.bossHousesInfoFlatsIconsContBlank {
  transform: translateX(-34px);
  position: relative;
  width: 65px;
  height: 65px;
  background: #ffb129;
  box-shadow: 0 4px 10px rgba(255, 177, 41, 0.3);
  border-radius: 11px;
}

.bossHousesInfoFlatsIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40.22px;
  height: 43.58px;
  color: #fff;
}

.bossHousesInfoFlatsPersentMain {
  padding: 5px 0;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 100%;
  color: #525274;
}

.bossHousesChartCont {
  margin-top: 10px;
  width: 505px;
  height: 322px;
  background-color: #f9f6ff;
  border-radius: 12px;
  overflow: hidden;
}

.bossHousesInfoFlatsPersentSecond {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #9090a9;
}

.SettingsMessageMainLabel {
  font-family: IBM Plex Sans, serif;
  cursor: default;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #4e4e50;
}

.employeePhoneInput {
  padding: 4px;
  border-radius: 0;
  outline-style: none;
  border: 1px solid #dcd8d8;
}

.employeePhoneInput:hover {
  border: 1px solid rgba(101, 141, 233, 0.6);
}

.employeePhoneInput:focus {
  border: 1px solid rgba(101, 141, 233, 0.6);
  box-shadow: 0 0 3px rgba(101, 141, 233, 0.6);
}

.BronModalInput {
  padding: 4px 10px;
  border-radius: 50px;
  outline-style: none;
  border: 1px solid #dcd8d8;
  width: 85%;
  margin: 3px 0;
}

.BronModalInput:hover {
  border: 1px solid rgba(101, 141, 233, 0.6);
}

.BronModalInput:focus {
  border: 1px solid rgba(101, 141, 233, 0.6);
  box-shadow: 0 0 3px rgba(101, 141, 233, 0.6);
}

.paymentPlusInput {
  border-radius: 4px;
  width: 150px;
  border: 1px solid #d9d9d9;
  padding: 2px 10px;
  outline-style: none;
}

.paymentPlusInput:hover {
  border: 1px solid rgba(101, 141, 233, 0.6);
}

.paymentPlusInput:focus {
  border: 1px solid rgba(101, 141, 233, 0.6);
  box-shadow: 0 0 3px rgba(101, 141, 233, 0.6);
}

.nextNumberButton {
  position: relative;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  margin: 5px;
}

.NowSelectNumber {
  user-select: none;
  position: relative;
  padding: 0 20px;
  height: 28px;
  background: #ffffff;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  margin: 5px;
}

.dashboardMenuItem {
  display: flex;
  width: 80%;
  border-radius: 2.4vw;
  text-decoration: none;
  align-items: center;
  padding-left: 1vw;
  margin: 0.5vh auto 0 2vw;
  transition: all ease-in 0.2s;
}

.dashboardMenuItem.logout {
  margin-top: 68vh;
}

.dashboardMenuItem.active {
  background-color: #6021a3;
}

.dashboardMenuItemIcon {
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  position: relative;
  color: #133133;
}

.dashboardMenuItemIcon.active {
  color: #fff;
}

.dashboardMenuItemText {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  padding-left: 0.5vw;
  color: #133133;
}

.dashboardMenuItemText.active {
  color: #fff;
}

.dashboardContentsTitleCont {
  font-family: IBM Plex Sans, serif;
  width: 94%;
  margin: 2% 0 2% 2%;
  padding: 0 1vw;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  color: #8f8e8e;
  box-shadow: 0 12px 25px rgba(88, 88, 88, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.monthlyPaymentsCopyPastIcon {
  text-align: center;
  color: #1b92ff;
  font-size: 1.6vw;
  cursor: pointer;
}

.language {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #989fb9;
  cursor: pointer;
}

.language.active {
  color: #7944e3;
}

.clientBalanceInfo {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #989fb9;
  cursor: default;
}

.ClientBalanceHeader {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 100%;
  display: block;
  margin: 10px 0 30px;
  text-align: start;
  color: #989fb9;
  cursor: default;
}

.clientBalanceInputLabel {
  width: 160px;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #989fb9;
}

.clientBalanceInput {
  width: 30%;
  margin-right: 5px;
}

.clientBalanceButton {
  width: 100px;
  margin-left: 5px;
}

.clientBalanceTableTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 100%;
  display: block;
  margin: 10px 0 30px;
  text-align: center;
  color: #989fb9;
  cursor: default;
}

.payModalHeader {
  width: 40%;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  padding: 7px;
  font-size: 1rem;
  color: #6d6d6f;
  text-align: center;
  cursor: pointer;
}

.payModalHeader.active {
  background: rgba(240, 240, 243, 1);
}

.inputNumberStyle {
  outline-style: none;
  padding: 4px 7px;
  border: 1px solid #d3d4d4;
  border-radius: 2px;
  color: #7d7d81;
}

.inputNumberStyle.payModal {
  width: 55%;
}

.inputNumberStyle.clientBalance {
  width: 65%;
}

.inputNumberStyle:hover,
.inputNumberStyle:focus {
  border: 1px solid #1bcaff;
}

.inputNumberStyle:focus {
  box-shadow: 0 0 4px rgba(27, 202, 255, 0.5);
}

.payModalLabel {
  width: 80px;
  display: inline-block;
  text-align: right;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
  line-height: 100%;
  color: #989fb9;
}

.inputNumberStyle.addBalance {
  margin-right: 10px;
}

.developerSearchInput {
  border-color: #fff;
  color: #989898;
  height: 6vh;
  font-size: 1.4vw;
}

.dashboardContentPlusIcon {
  background-color: #6021a3;
  width: 2.5vw;
  height: 2.5vw;
  color: #fff;
}

.dashboardContentPlusIcon:hover,
.dashboardContentPlusIcon:focus {
  border-color: #6021a3;
  background-color: #6021a3;
  color: #fff;
}

.dashboardContentsTableCon {
  font-family: IBM Plex Sans, serif;
  width: 94%;
  margin: 2% 2% 0;
  padding: 0 1vw;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  color: #8f8e8e;
  box-shadow: 0 12px 25px rgba(88, 88, 88, 0.05);
  border-radius: 5px;
}

.developerTableCompanyActionButtons {
  margin: 0 5px;
  background-color: #6021a3;
}

.developerTableCompanyActionButtons:hover,
.developerTableCompanyActionButtons:focus {
  border-color: #6021a3;
  background-color: #6021a3;
  color: #fff;
}

.developerEditModalTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4vw;
  line-height: 1.5vw;
  color: #133133;
  margin-top: 10px;
}

.developerEditModalCont {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.developerEditModalLabel {
  width: 55%;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.4vw;
  color: #133133;
}

.developerEditModalLabelAddress {
  margin-top: 10px;
  display: inline-block;
  width: 55%;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.4vw;
  color: #133133;
}

.developerEditModalLabelSwitch {
  width: 35%;
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.4vw;
  color: #133133;
}

.developerEditModalInput.file {
  width: 50%;
}

.developerEditModalUploadLabel {
  display: inline-block;
  padding: 5px;
  margin-left: 3px;
  width: 25%;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #6021a3;
  cursor: pointer;
  border-radius: 5px;
}

.developerEditModalSaveButton {
  display: flex;
  justify-content: center;
  padding: 4px;
  width: 20%;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 6px;
  background-color: #6021a3;
  color: #fff;
}

.developerEditModalSaveButton {
  margin-left: auto;
}

.developerEditModalSaveButton:hover,
.developerEditModalSaveButton:focus {
  border-color: #6021a3;
  background-color: #6021a3;
  color: #fff;
}

.inputNumberStyle.developerEditModalInputPhone {
  width: 100%;
}

.monthlyPaymentsChange {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #133133;
  cursor: pointer;
  transition: 0.2s all ease-in;
}

.monthlyPaymentsChange:hover {
  color: #1b92ff;
}

.arxivTableMonthlyPayment {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.4vw;
  color: #133133;
}

.paymentSearchResultTitle {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2vw;
  color: #8f8e8e;
}

.paymentSearchResult {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4vw;
  color: #8f8e8e;
}

.paymentSearchResult .paymentSearchResultSpan {
  display: inline-block;
  width: 160px;
}

.contractPlusInputsCont {
  display: flex;
}

.contractPlusSpin {
  position: fixed;
  z-index: 1001;
  left: 58%;
}

.contractPlusSpin.false {
  top: 20px;
  animation: openSpinnerAnimation 1s linear forwards;
}

.contractPlusSpin.true {
  top: -100px;
  animation: closeSpinnerAnimation 1s linear forwards;
}

@keyframes openSpinnerAnimation {
  0% {
    top: 20px;
  }
  100% {
    top: -100px;
  }
}

@keyframes closeSpinnerAnimation {
  0% {
    top: -100px;
  }
  100% {
    top: 20px;
  }
}

.ant-table-cell {
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #434a59;
}

.responsiveBarLegendsCont {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.responsiveBarLegendsShapeRed {
  background: #e65c62;
  cursor: pointer;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 10px rgba(230, 92, 98, 0.3);
  border-radius: 7px;
}

.responsiveBarLegendsShapeGreen {
  background: #b7e05e;
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 10px rgba(183, 224, 94, 0.3);
  border-radius: 7px;
}

.responsiveBarLegendsShapeOrange {
  background: #ffb129;
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 10px rgba(255, 177, 41, 0.3);
  border-radius: 7px;
}

.responsiveBarLegendsShapeDisable {
  background: rgb(175, 175, 172);
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 10px rgb(108, 108, 104);
  border-radius: 7px;
}

.responsiveBarLegendsShapeBlue {
  background: #67d2e0;
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 10px rgba(103, 210, 224, 0.3);
  border-radius: 7px;
}

.responsiveBarLegendsShapePink {
  background: #9b75ec;
  cursor: pointer;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  box-shadow: 0 4px 10px rgba(155, 117, 236, 0.3);
  border-radius: 7px;
}

.responsiveBarLegendsTyp {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  cursor: default;
}

.clientSendMessage {
  display: flex;
  justify-content: center;
}

.clientSendMessageIcon {
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  font-size: 2rem;
}

.clientSendMessageIcon:hover {
  background-color: #a1a0a0;
}

/* creating contract popover links */
.popoverLinks a {
  color: #133133;
  font-size: 1rem;
  font-weight: 600;
  transition: 0.2s;
}
.popoverLinks a:hover {
  color: #1890ff;
}

.txt-btn {
  color: #1890ff;
}

.sort_cont {
  display: flex;
  flex-direction: column;
}

.sort_cont svg {
  width: 10px;
  height: 8px;
}

.sort_cont.active svg.active {
  fill: rgb(0, 0, 0, 85);
}

.excelBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
}
.excelBtn:hover svg path {
  fill: white;
}
.excelBtn svg {
  margin-right: 4px;
}
.excelBtn svg path {
  transition: all ease-out 0.3s;
}

.arrearage_excel_btn {
  display: inline-flex;
  align-items: center;
}
.arrearage_excel_btn svg {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-right: 3px;
}
.arrearage_excel_btn:hover svg {
  fill: #1890ff;
}
