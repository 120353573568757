.productCont {
  width: 95%;
  margin: 40px auto 20px;
  .title {
    font-family: IBM Plex Sans, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #989fb9;
  }

  .header {
    margin-top: 10px;
    padding: 10px;
  }

  .body {
    background: #ffffff;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    border-radius: 6px;

    .input {
      width: 90%;
    }

    .sum {
      font-family: IBM Plex Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 100%;
      color: #7944e3;
    }

    .iconsCont {
      .save {
        &active {
          font-size: 20px;
          cursor: pointer;
          color: #7944e3;
        }
        &noActive {
          font-size: 20px;
          cursor: pointer;
          color: #888888;
        }
      }
      .delete {
        color: #ec3333;
        font-size: 20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .buttonAdd {
    margin-top: 20px;
    display: flex;
    border-radius: 6px;
    border: 1px dashed #7944e3;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    font-family: IBM Plex Sans, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #7944e3;
    span {
      margin-left: 10px;
    }
  }
}

//special contract
.specialContract {
  label {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    span.title {
      min-width: 10rem;
    }
    .ant-row {
      margin-bottom: 0;
      width: 20rem;
    }
  }
  .ant-picker {
    width: 100%;
  }
  .ant-checkbox-checked {
    &::after {
      display: none;
    }
  }
  .clientPhone {
    border: 1px solid #d9d9d9 !important;
    border-top-color: rgb(217, 217, 217) !important;
    border-top-style: solid !important;
    border-top-width: 1px !important;
    border-right-color: rgb(217, 217, 217) !important;
    border-right-style: solid !important;
    border-right-width: 1px !important;
    border-bottom-color: rgb(217, 217, 217) !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-left-color: rgb(217, 217, 217) !important;
    border-left-style: solid !important;
    border-left-width: 1px !important;
  }
}

.productCont {
  .btnCont {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    button {
      margin: 0 1rem;
    }
  }
}

//auto complete
.autoComplete {
  background: #ffffff;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
}
